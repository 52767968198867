import {createRouter, createWebHistory} from 'vue-router'

export default createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: "/:catchAll(.*)",
      redirect: "/index"
    },
    {
      path:'/index',
      name:'index',
      component:()=> import('@/views/home'),
      meta: {
        title:"首页"
      }
    },
    {
      path:'/about',
      name:'about',
      component:()=> import('@/views/about'),
      meta: {
        title:"关于我们"
      }
    }
    ,
    {
      path:'/product',
      name:'product',
      component:()=> import('@/views/product'),
      meta: {
        title:"API服务产品"
      }
    },{
      path:'/detail',
      name:'productDetail',
      component:()=> import('@/views/product/detail'),
      meta: {
        title:"产品详情"
      }
    },{
      path:'/plan',
      name:'plan',
      component:()=> import('@/views/plan'),
      meta: {
        title:"解决方案"
      }
    },{
      path:'/plandetail',
      name:'plandetail',
      component:()=> import('@/views/plan/detail'),
      meta: {
        title:"方案详情"
      }
    },{
      path:'/authpro',
      name:'authpro',
      component:()=> import('@/views/authpro'),
      meta: {
        title:"实我核验产品"
      }
    },{
      path:'/news',
      name:'news',
      component:()=> import('@/views/news/index'),
      meta: {
        title:"新闻资讯"
      }
    },{
      path:'/newsdetail/:id(\\d+)',
      name:'newsdetail',
      component:()=> import('@/views/news/detail'),
      meta: {
        title:"新闻详情"
      }
    },
  ],
  scrollBehavior(){
    return {top:0}
  }
})